.image-gallery-image {
    border-radius: 6px;
    border-width: 2px;
}
.image-gallery-thumbnail-image {
    border-radius: 4px;
}
.image-gallery-thumbnail.active, .image-gallery-thumbnail:focus, .image-gallery-thumbnail:hover {
    border-radius: 6px;
    border-width: 2px;
    border-color: rgba(91, 164, 58, 1.0);
}
.image-gallery-description {
    border-top-right-radius: 8px;
    border-bottom-right-radius: 8px;
    font-family: "Barlow", "sans-serif";
    background-color: rgb(10, 25, 41, 0.6) !important;
}
.image-gallery-icon:hover {
    color: rgba(91, 164, 58, 1.0);
}
.image-gallery-thumbnails {
    padding-top: 8px;
}
.image-gallery-thumbnail {
    vertical-align: top;
}
