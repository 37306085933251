.slick-slider {
    overflow: hidden;
}
.slick-arrow {
    color: #fff !important;
    border: none;
    outline: none;
}
.slick-prev {
    left: 3% !important;
    z-index: 1;
}
.slick-next {
    right: 3% !important;
    z-index: 1;
}

.slick-prev:before,
.slick-next:before
{
    font-size: 32px;
    opacity: .75;
    color: #fff;
}